import {
  Button,
  Container,
  Heading,
  Page,
  Section,
  Spacer,
} from "@python-italia/pycon-styleguide";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import Head from "next/head";

const NotFoundPage = () => {
  useEffect(() => {
    document.body.classList.remove("bg-milk");
    document.body.classList.add("bg-blue");
    return () => {
      document.body.classList.remove("bg-blue");
      document.body.classList.add("bg-milk");
    };
  });
  return (
    <Page endSeparator={false}>
      <Head>
        <title>404</title>
      </Head>
      <Section>
        <Container
          noPadding
          size="medium"
          className="text-center flex flex-col items-center"
        >
          <FourOhFourIllustration />
          <Spacer size="small" />
          <Heading size="display2">
            <FormattedMessage id="404.message" />
          </Heading>
          <Spacer size="large" />
          <Button role="primary" href="/" fullWidth="mobile">
            <FormattedMessage id="404.goToHomepage" />
          </Button>
        </Container>
      </Section>
    </Page>
  );
};

const FourOhFourIllustration = () => (
  <svg
    width="584"
    height="286"
    viewBox="0 0 584 286"
    fill="none"
    className="max-w-[584px] w-full max-h-[150px] md:max-h-[200px] lg:max-h-[286px]"
  >
    <path
      d="M144.077 206.994H120.631V236H79.2975V206.994H2.43231V175.813L86.7906 72.6011H120.631V170.737H144.077V206.994ZM47.3912 170.737H79.2975V130.613L47.3912 170.737Z"
      fill="#0E1116"
    />
    <path
      d="M579.077 206.994H555.631V236H514.297V206.994H437.432V175.813L521.791 72.6011H555.631V170.737H579.077V206.994ZM482.391 170.737H514.297V130.613L482.391 170.737Z"
      fill="#0E1116"
    />
    <rect
      x="409.5"
      y="92.5"
      width="122"
      height="235"
      rx="61"
      transform="rotate(90 409.5 92.5)"
      stroke="#0E1116"
      strokeWidth="43"
    />
    <g clip-path="url(#clip0_1092_52592)">
      <path
        d="M281 97.3268V242.964C281 242.969 281 242.974 280.998 242.979C280.997 242.983 280.994 242.987 280.991 242.991C280.987 242.994 280.983 242.997 280.978 242.998C280.973 243 280.968 243 280.963 243H256.144C265.377 233.533 265.34 214.805 256.018 205.434C246.695 196.062 246.663 177.149 256.018 167.73C265.373 158.311 265.377 139.461 256.018 130.026C246.659 120.591 246.663 101.757 256.018 92.3261C265.251 83.029 264.196 64.5528 255.166 55C262.976 59.1764 269.49 65.3044 274.033 72.7475C278.576 80.1906 280.981 88.6775 281 97.3268Z"
        fill="#F17A5D"
      />
      <path
        d="M256.226 129.804C265.24 139.265 265.24 158.144 256.226 167.582C247.213 177.019 247.217 195.91 256.226 205.36C265.236 214.809 265.24 233.515 256.347 243H232.207C232.202 243 232.197 243 232.193 242.998C232.188 242.997 232.184 242.994 232.18 242.991C232.177 242.987 232.174 242.983 232.173 242.979C232.171 242.974 232.171 242.969 232.172 242.964V96.114C232.172 96.0864 232.148 96.0746 232.121 96.0746H187.035C187.012 96.0746 187 96.051 187 96.0273C187.01 83.7178 191.798 71.902 200.339 63.1128C208.881 54.3236 220.494 49.2605 232.691 49.0089C240.619 48.8511 248.45 50.7891 255.406 54.6305C264.103 64.2021 265.118 82.7146 256.226 92.0299C247.217 101.479 247.217 120.366 256.226 129.804ZM223.932 70.4031C223.933 69.6298 223.708 68.8734 223.283 68.2297C222.858 67.5861 222.253 67.0841 221.545 66.7875C220.838 66.4908 220.059 66.4128 219.307 66.5633C218.555 66.7137 217.864 67.086 217.322 67.6328C216.781 68.1796 216.412 68.8765 216.262 69.635C216.113 70.3936 216.191 71.1798 216.485 71.8939C216.779 72.6081 217.276 73.2182 217.914 73.6468C218.552 74.0755 219.302 74.3035 220.068 74.302C221.092 74.3009 222.075 73.8898 222.799 73.1589C223.523 72.4279 223.931 71.4368 223.932 70.4031Z"
        fill="#FCE8DE"
      />
      <path
        d="M220.008 67C220.799 67.0014 221.571 67.2079 222.228 67.5933C222.885 67.9787 223.396 68.5258 223.698 69.1655C223.999 69.8052 224.077 70.5087 223.922 71.1871C223.767 71.8656 223.385 72.4886 222.826 72.9774C222.266 73.4661 221.553 73.7988 220.777 73.9333C220.002 74.0677 219.198 73.998 218.467 73.7329C217.737 73.4678 217.113 73.0192 216.674 72.4438C216.234 71.8684 216 71.1919 216 70.5C216 70.0398 216.104 69.5841 216.305 69.159C216.507 68.7339 216.802 68.3477 217.174 68.0226C217.547 67.6975 217.989 67.4399 218.475 67.2644C218.961 67.0889 219.482 66.9991 220.008 67Z"
        fill="#0E1116"
      />
      <path
        d="M256.879 243H232.553C232.548 243 232.543 243 232.539 242.998C232.534 242.997 232.53 242.994 232.527 242.991C232.523 242.987 232.521 242.983 232.519 242.979C232.517 242.974 232.517 242.969 232.518 242.964V96.114C232.518 96.0864 232.494 96.0746 232.466 96.0746H187.035C187.012 96.0746 187 96.051 187 96.0273C187.01 83.7178 191.835 71.902 200.442 63.1128C209.048 54.3236 220.751 49.2605 233.041 49.0089C241.03 48.8511 248.921 50.7891 255.93 54.6305C263.509 58.8151 269.831 64.9551 274.239 72.4129C278.648 79.8706 280.982 88.3741 281 97.0405V242.964C281 242.969 281 242.974 280.998 242.979C280.997 242.983 280.994 242.987 280.991 242.991C280.987 242.994 280.983 242.997 280.979 242.998C280.974 243 280.969 243 280.964 243H256.879Z"
        stroke="#0E1116"
        strokeWidth="3"
        strokeMiterlimit="1"
      />
      <path
        d="M255.998 243C256.039 242.949 256.087 242.914 256.124 242.863C265.331 233.423 265.295 214.748 255.998 205.402C246.702 196.057 246.669 177.197 255.998 167.805C265.327 158.412 265.331 139.615 255.998 130.207C246.665 120.799 246.669 102.018 255.998 92.6133C265.206 83.3424 264.154 64.9183 255.149 55.3923C255.038 55.2557 252.624 53.1247 252.5 53"
        stroke="#0E1116"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M212.29 97V112.08L205 119V97H220V112.08L212.71 119V97"
        fill="#E94135"
      />
      <path
        d="M212.29 97V112.08L205 119V97H220V112.08L212.71 119V97"
        stroke="#0E1116"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </g>
    <g clip-path="url(#clip1_1092_52592)">
      <path
        d="M302.012 44.9047C302.012 44.8816 301.986 37 302.012 37H325.315V37.3114C316.402 46.8962 316.402 66.0582 325.315 75.6314C334.228 85.2047 334.228 104.378 325.315 113.963C316.402 123.548 316.402 142.698 325.315 152.283C334.228 161.868 334.228 181.03 325.315 190.607C316.402 200.184 317.542 219.354 326.455 228.938L326.433 229C319.074 224.994 312.911 218.986 308.607 211.621C304.303 204.256 302.023 195.816 302.012 187.208V44.9047Z"
        fill="#FCE8DE"
      />
      <path
        d="M326.714 152.238C317.762 142.657 317.762 123.502 326.714 113.933C335.667 104.363 335.667 85.1974 326.714 75.6164C317.762 66.0354 317.762 46.8925 326.714 37.3114V37.0001H349.245C349.223 37.0001 349.245 36.8695 349.245 44.9017V187.149C349.26 195.195 351.266 203.105 355.072 210.13C358.879 217.156 364.361 223.066 371 227.303C364.293 231.532 356.611 233.84 348.749 233.99C341.477 234.149 334.284 232.412 327.837 228.94L327.86 228.879C318.907 219.298 317.762 200.143 326.714 190.562C335.667 180.981 335.656 161.811 326.714 152.238Z"
        fill="#F17A5D"
      />
      <path
        d="M371 227.303C364.065 231.532 356.124 233.84 347.995 233.99C340.476 234.149 333.039 232.412 326.374 228.94C318.729 224.935 312.327 218.926 307.857 211.561C303.388 204.197 301.021 195.756 301.012 187.149V44.9016C301.012 44.8785 300.985 37 301.012 37H348.508"
        stroke="#0E1116"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M371 227.439C364.361 223.199 358.879 217.284 355.072 210.254C351.266 203.223 349.26 195.308 349.245 187.256V44.9073C349.245 36.8694 349.223 37.0001 349.245 37.0001M326.714 37.3116C317.762 46.8995 317.762 66.0676 326.714 75.6439C335.667 85.2203 335.667 104.4 326.714 113.988C317.762 123.576 317.762 142.732 326.714 152.32C335.667 161.908 335.667 181.076 326.714 190.656C317.762 200.236 319.547 220.412 328.5 230"
        stroke="#0E1116"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1092_52592">
        <rect
          width="100"
          height="147"
          fill="white"
          transform="translate(184 46)"
        />
      </clipPath>
      <clipPath id="clip1_1092_52592">
        <rect
          width="74"
          height="122"
          fill="white"
          transform="matrix(-1 0 0 -1 373 236)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default NotFoundPage;
